//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #f7f7f9 !default;
$gray-300: #eceeef !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #919aa1 !default;
$gray-700: #55595c !default;
$gray-800: #3a3a3a !default;
$gray-900: #1a1a1a !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9534f !default;
$orange: #fd7e14 !default;
$yellow: #f0ad4e !default;
$green: #4bbf73 !default;
$teal: #20c997 !default;
$cyan: #1f9bcf !default;

$primary: #f50537 !default;
$secondary: $gray-800 !default;
$success: $green !default;
$info: #00afda !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-300 !default;
$dark: $black !default;

$min-contrast-ratio: 2.3 !default;

// Options

$enable-rounded: false !default;

// Body

$body-color: $black !default;
$footer-color: #fff;

// Fonts

@font-face {
	font-family: "Audi Type Extended";
	src: url("../../fonts/auditype/AudiType-ExtendedBold.eot");
	src: url("../../fonts/auditype/AudiType-ExtendedBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/auditype/AudiType-ExtendedBold.woff2") format("woff2"),
		url("../../fonts/auditype/AudiType-ExtendedBold.woff") format("woff"), url("../../fonts/auditype/AudiType-ExtendedBold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Audi Type Extended";
	src: url("../../fonts/auditype/AudiType-ExtendedNormal.eot");
	src: url("../../fonts/auditype/AudiType-ExtendedNormal.eot?#iefix") format("embedded-opentype"), url("../../fonts/auditype/AudiType-ExtendedNormal.woff2") format("woff2"),
		url("../../fonts/auditype/AudiType-ExtendedNormal.woff") format("woff"), url("../../fonts/auditype/AudiType-ExtendedNormal.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Audi Type";
	src: url("../../fonts/auditype/AudiType-Normal.eot");
	src: url("../../fonts/auditype/AudiType-Normal.eot?#iefix") format("embedded-opentype"), url("../../fonts/auditype/AudiType-Normal.woff2") format("woff2"),
		url("../../fonts/auditype/AudiType-Normal.woff") format("woff"), url("../../fonts/auditype/AudiType-Normal.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Audi Type";
	src: url("../../fonts/auditype/AudiType-Bold.eot");
	src: url("../../fonts/auditype/AudiType-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/auditype/AudiType-Bold.woff2") format("woff2"),
		url("../../fonts/auditype/AudiType-Bold.woff") format("woff"), url("../../fonts/auditype/AudiType-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-family-tipo: "Audi Type Extended", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-family-headings: $font-family-tipo;

$font-family-menu: $font-family-sans-serif;

$font-size-base: 0.9rem !default;

$h1-font-size: 2rem !default;
$h2-font-size: 1.75rem !default;
$h3-font-size: 1.5rem !default;
$h4-font-size: 1.25rem !default;
$h5-font-size: 1rem !default;
$h6-font-size: 0.75rem !default;
$headings-font-family: $font-family-headings;
$headings-font-weight: 600 !default;
$headings-color: $gray-900 !default;

// Tables

$table-border-color: rgba(0, 0, 0, 0.05) !default;

// Buttons + Forms

$input-btn-border-width: 1px !default;

$border-color: transparent !default;

// Buttons

$btn-line-height: 1.5rem !default;
$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 1.5rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 1rem !default;
$input-btn-padding-y-lg: 2rem !default;
$input-btn-padding-x-lg: 2rem !default;
$btn-font-weight: 600 !default;

// Forms

$input-line-height: 1.5 !default;
$input-bg: $gray-200 !default;
$input-disabled-bg: $gray-300 !default;
$input-group-addon-bg: $gray-300 !default;

// Navbar

$navbar-padding-y: 1.5rem !default;
$navbar-dark-hover-color: $white !default;
$navbar-light-color: rgba($black, 0.3) !default;
$navbar-light-hover-color: $gray-900 !default;
$navbar-light-active-color: $gray-900 !default;

// Pagination

$pagination-border-color: transparent !default;
$pagination-hover-border-color: $pagination-border-color !default;
$pagination-disabled-border-color: $pagination-border-color !default;

$list-group-item-padding-y: 1rem;
$list-group-item-padding-x: 1rem;

// Bootstrap variables end: Import Bootstrap

@import "./bootstrap/scss/bootstrap";

/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ========================================== 
*
*/

html {
	scroll-behavior: smooth;
}

/*
@include media-breakpoint-up(sm) { ... }
@include media-breakpoint-up(md) { ... }
@include media-breakpoint-up(lg) { ... }
@include media-breakpoint-up(xl) { ... }
@include media-breakpoint-up(xxl) { ... }
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { ... }


@include media-breakpoint-down(sm) { ... }
@include media-breakpoint-down(md) { ... }
@include media-breakpoint-down(lg) { ... }
@include media-breakpoint-down(xl) { ... }
@include media-breakpoint-down(xxl) { ... }

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { ... }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { ... }

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { ... }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { ... }

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { ... }

*/

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	text-transform: uppercase;
}

/* btn font */
.btn {
	text-transform: uppercase;
}

.list-group {
	a {
		color: $body-color;
	}
}

#mainNav {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	background-color: #fff;
	transition: background-color 0.2s ease;
	padding-top: 1rem;
	padding-bottom: 1rem;

	> div {
		position: relative;
	}

	.navbar-brand {
		padding: 0;
		.navbar-brand-title {
			font-weight: bold;
			color: $dark;
			font-family: $font-family-tipo;
			font-size: 0.5rem;
			margin-left: 1rem;
		}
	}

	.logo {
		img {
			height: 20px;
			transition: filter 0.5s ease-in-out;
			filter: invert(1);
		}
	}

	.navbar-nav {
		li {
			&:hover {
				> ul.dropdown-menu {
					display: block;
				}
			}
		}

		.nav-item {
			.nav-link {
				color: $dark;
				font-family: $font-family-menu;
				font-weight: bold;
				text-transform: uppercase;
				font-size: 1rem;
				padding: 1rem 0;

				&:hover {
					color: $primary;
				}
			}

			.nav-link.active {
				color: $black;

				&::before {
					content: "";
					position: absolute;
					display: block;
					width: 100%;
					height: 2px;
					bottom: 0;
					left: 0;
					background-color: $white;
					transform: scaleX(1);
				}
			}
		}
	}

	.dropdown-menu {
		padding: 0.5rem 0;
		margin: 0;
		font-size: 1rem;
		color: $dark;
		background-color: $white;
		border: none;

		> li {
			> a {
				&:hover {
					&:after {
						text-decoration: underline;
						transform: rotate(-90deg);
					}
				}
			}
		}
	}

	.dropdown-submenu {
		position: relative;

		> .dropdown-menu {
			top: 0;
			left: 100%;
			margin-top: -6px;
		}
	}

	.menu-trigger {
		cursor: pointer;
		display: block;
		position: absolute;
		top: 8px;
		right: 20px;
		width: 30px;
		height: 40px;
		text-indent: -9999em;
		z-index: 99;
		border-color: transparent;

		&:active {
			outline: none;
			border: none;
			border-color: transparent;
			box-shadow: none;
		}

		&:focus {
			outline: none;
			border: none;
			border-color: transparent;
			box-shadow: none;
		}

		span {
			transition: all 0.4s;
			background-color: #1e1e1e;
			display: block;
			position: absolute;
			width: 30px;
			height: 2px;
			left: 0;
			top: 16px;

			&:before {
				transition: all 0.4s;
				background-color: #1e1e1e;
				display: block;
				position: absolute;
				width: 30px;
				height: 2px;
				left: 0;
				content: "";
				transform-origin: 33% 100%;
				top: -10px;
				z-index: 10;
			}

			&:after {
				transition: all 0.4s;
				background-color: #1e1e1e;
				display: block;
				position: absolute;
				width: 30px;
				height: 2px;
				left: 0;
				content: "";
				transform-origin: 33% 0;
				top: 10px;
			}
		}

		&.active {
			span {
				background-color: transparent;
				width: 100%;

				&:before {
					background-color: transparent;
					width: 100%;
					transform: translateY(6px) translateX(1px) rotate(45deg);
					background-color: #1e1e1e;
				}

				&:after {
					background-color: transparent;
					width: 100%;
					transform: translateY(-6px) translateX(1px) rotate(-45deg);
					background-color: #1e1e1e;
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	#mainNav {
		box-shadow: none;
		background-color: transparent;

		.navbar-brand {
			padding: 1rem 0;
			color: rgba($white, 0.7);

			&:hover {
				color: $white;
			}

			.navbar-brand-title {
				font-size: 0.7rem;
				color: $white;
			}
		}

		.logo {
			img {
				filter: initial;
			}
		}

		.navbar-nav {
			.nav-item {
				.nav-link {
					color: rgba($white, 0.7);
					padding: 0.5rem 0.8rem;
					position: relative;
					font-size: 0.5rem;
					white-space: nowrap;

					&:hover {
						color: $white;
					}

					&::before {
						content: "";
						position: absolute;
						display: block;
						width: 100%;
						height: 2px;
						bottom: 0;
						left: 0;
						background-color: $white;
						transform: scaleX(0);
						transform-origin: top left;
						transition: transform 0.3s ease;
					}

					&:hover::before {
						transform: scaleX(1);
					}

					&.active {
						color: $white;
					}
				}

				&:last-child {
					.nav-link {
						padding-right: 0;
					}
				}
			}
		}

		.menu-trigger {
			display: none;
		}

		.dropdown-menu {
			color: $white;
			text-align: left;
			background-color: $dark;
			border: transparent;

			.dropdown-item {
				font-family: $font-family-menu;
				font-size: 11px;
				font-weight: 600;
				text-transform: uppercase;
				padding: 0.8rem 1rem;
				font-weight: 400;
				color: $white;

				&:hover {
					background-color: $dark;
					color: $primary;
				}
			}
		}
	}

	#mainNav.navbar-shrink {
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
		background-color: $white;

		.navbar-brand {
			color: $dark;
			.navbar-brand-title {
				color: $dark;
			}

			&:hover {
				color: $primary;
			}

			&.logo {
				img {
					filter: invert(1);
				}
			}
		}

		.navbar-nav {
			.nav-item {
				.nav-link {
					color: $dark;

					&:hover {
						color: $primary;
					}

					&::before {
						background-color: $primary;
					}

					&:active {
						color: $black;
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(lg) {
	#mainNav {
		padding-top: 1rem;
		padding-bottom: 1rem;

		.dropdown-item {
			padding: 0.5rem 0;
			clear: both;
			font-size: 0.9rem;
			font-weight: normal;
			color: $gray-700;
			background-color: transparent;
			border: 0;
		}

		.navbar-nav .nav-item .nav-link {
			color: $dark;
		}
	}
}

@include media-breakpoint-up(xl) {
	#mainNav {
		.logo {
			img {
				height: 30px;
			}
		}
	}
}

header.masthead {
	padding-top: 0;
	padding-bottom: 0;
	background: url("../../img/front/bg-calendario.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-size: cover;
	position: relative;
	height: 100vh;
	overflow: hidden;

	p {
		font-size: 1.15rem;
	}

	h1,
	.h1 {
		font-weight: bold;
		color: $white;
		font-size: 2rem;
		margin-bottom: 1rem;
	}

	h2,
	.h2 {
		color: $white;
		font-weight: 400;
		font-size: 1.6rem;
	}

	@include media-breakpoint-up(lg) {
		height: 100vh;
		min-height: 40rem;
		padding-top: 0;
		padding-bottom: 0;

		p {
			font-size: 1.15rem;
		}

		h1,
		.h1,
		h2,
		.h2 {
			font-weight: bold;
			color: $white;
		}

		h1,
		.h1 {
			font-size: 3rem;
			margin-bottom: 2rem;
		}

		h2,
		.h2 {
			font-size: 2rem;
			font-weight: 400;
		}
	}
}

header.masthead-introduce {
	min-height: 400px;
	height: 50vh;
	display: flex;
	align-items: center;

	h1,
	h2 {
		color: $white;
		text-transform: uppercase;
	}

	h1.page-introduce-title {
		font-size: 1.5rem;
		margin-top: 4rem;
		font-weight: bold;
	}

	h2.page-introduce-subtitle {
		font-size: 1.5rem;
		font-weight: normal;
	}

	@include media-breakpoint-up(md) {
		height: 50vh;

		h1.page-introduce-title {
			font-size: 2rem;
			margin-top: 7rem;
		}

		h2.page-introduce-subtitle {
			font-size: 1.7rem;
		}
	}
}

#bg-video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
}

#bg-video::-webkit-media-controls {
	display: none !important;
}

.video-overlay {
	position: absolute;
	background-color: rgba($dark, 0.1);
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
}

.btn-scroll-to-intro {
	display: block;
	width: 50px;
	height: 50px;
	margin: auto;
	margin-bottom: 50px;
	line-height: 50px;
	text-align: center;
	font-size: 2rem;
	color: $white;
	border-radius: 100%;
	border: 1px solid $white;

	&:hover {
		color: $white;
		line-height: 55px;
	}
}

#page {
	background-color: $white;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}

.page-bg {
	background: url(../../img/front/bg-home.jpg);
}

.page-bg-que-es {
	background: url(../../img/front/bg-que-es.jpg);
}

.page-bg-calendario {
	background: url(../../img/front/bg-calendario.jpg);
}

.page-bg-particulares {
	background: url(../../img/front/bg-particulares.jpg);
}

.page-bg-club {
	background: url(../../img/front/bg-club.jpg);
}

.page-bg-clasificaciones {
	background: url(../../img/front/bg-clasificaciones.jpg);
}

.page-bg-normativa {
	background: url(../../img/front/bg-normativa.jpg);
}

.page-bg-galeria {
	background: url(../../img/front/bg-galeria.jpg);
}

.page-bg-prensa {
	background: url(../../img/front/bg-prensa.jpg);
}

.page-bg-app {
	background: url(../../img/front/bg-neumatico.jpg);
}

.page-bg-test-drive {
	background: url(../../img/front/bg-test-drive.jpg);
}

/* footer */

#page-footer {
	font-size: 0.9rem;
	color: $footer-color;
	font-weight: normal;

	.footer-content {
		h3 {
			text-transform: uppercase;
			font-size: 1rem;
			color: $primary;
			font-weight: 800;
		}

		a {
			color: $footer-color;
			text-decoration: none;

			&:hover {
				color: $primary;
			}
		}

		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}

	.logo-footer {
		width: auto;
		height: 30px;
		margin-bottom: 2rem;

		@include media-breakpoint-down(md) {
			height: 40px;
		}
	}

	.footer-social {
		padding: 1rem 0;

		a {
			font-size: 16px;
			line-height: 25px;
			text-align: center;
			display: inline-block;
			background-color: $footer-color;
			border-radius: 100%;
			width: 25px;
			height: 25px;
			margin-right: 10px;
			color: $dark !important;

			i.fa {
			}
		}
	}
}

.list-info {
	list-style: none;
	margin: 0;
	padding: 1rem 0;

	li {
		margin-bottom: 0.5rem;

		span {
			margin-left: 0.5rem;
		}
	}
}

.scroll-to-top {
	position: fixed;
	right: 15px;
	bottom: 15px;
	display: none;
	width: 50px;
	height: 50px;
	text-align: center;
	color: $white;
	background: rgba(52, 58, 64, 0.5);
	line-height: 45px;

	&:focus,
	&:hover {
		color: $white;
	}

	&:hover {
		background: #343a40;
	}

	i {
		font-weight: 800;
	}
}

/* Gallery */

.gallery {
	figure {
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		background: $white;
		overflow: hidden;

		img {
			width: 100%;
			transform: scale(1);
			transition: 0.3s ease-in-out;
			aspect-ratio: 4 / 3;
			object-fit: cover;
		}

		&:hover img {
			transform: scale(1.3);
		}
	}
}

.page-gallery .gallery {
	figure {
		border: 5px solid $white;
	}
}

// Divider
.divider {
	display: flex;
	align-items: center;
	margin: 1em -1em;

	&:before,
	&:after {
		content: "";
		flex: 1;
		height: 1px;
		margin: 0 1em;
		background: $body-color;
		opacity: 0.25;
	}
}

/* helpers */

.title {
	color: $primary;
	font-weight: bold;
	text-transform: uppercase;
	border-bottom: 1px solid $primary;
	padding: 0.8rem 0;
	margin-bottom: 2rem;
}

hr.divider-title {
	background-color: $primary;
	border-top-width: 5px;
	width: 40px;
	margin: 1rem auto;
	height: 5px;
	opacity: 1;
}

figure.figure-effect {
	position: relative;

	a {
		img {
			opacity: 1;
			display: block;
			width: 100%;
			height: auto;
			transition: 0.5s ease;
			backface-visibility: hidden;
		}
	}

	.figure-effect__hover {
		background-color: $primary;
		transition: 0.5s ease;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		transform: translate(-50%, -50%);
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		i.icon {
			color: $white;
			font-size: 3rem;
		}
	}

	&:hover {
		.figure-effect__hover {
			opacity: 0.8;
		}
	}
}

.mega-title {
	font-size: 2rem;
	letter-spacing: 2px;
	margin-bottom: 1rem;
	color: $gray-500;
	text-align: center;

	@include media-breakpoint-up(md) {
		font-size: 3.2rem;
		letter-spacing: 4px;
		margin-bottom: 2rem;
	}
}

.figure-logo {
	max-width: 300px;
	height: auto;
	margin: auto;
}

ul.list-items {
	list-style: none;
	padding: 0;
	margin: 0 0 1rem 0;

	li {
		padding: 0rem 0 1rem 1.3rem;
	}

	li:before {
		color: $primary;
		content: "\f054";
		font-family: "Font Awesome 5 Free";
		font-size: 0.7rem;
		display: inline-block;
		margin-left: -1.3rem;
		width: 1.3rem;
		font-weight: 900;
	}

	.list-items {
		padding: 0.5rem 0;

		li:before {
			color: $gray-400;
		}

		li {
			padding: 0rem 0 0.5rem 1.3rem;
		}
	}
}

ul.list {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		padding: 0;
		margin: 0 0 1rem 0;
	}
}

#loader {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $white;
	position: absolute;
	z-index: 9999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// BOX

.box {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	width: 100%;
	background: $dark;
	text-align: center;
	cursor: pointer;

	img {
		position: relative;
		display: block;
		min-height: 100%;
		max-width: 100%;
		opacity: 0.8;
	}

	.box-content {
		padding: 2em;
		color: $white;
		text-transform: uppercase;
		backface-visibility: hidden;

		&::before,
		&::after {
			pointer-events: none;
		}
	}

	img {
		max-width: 100%;
		opacity: 0.7;
		transition: opacity 0.35s, transform 0.35s;
		transform: scale(1.1);
	}

	.box-content {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 2em;
		width: 100%;
		height: 50%;
	}

	h2,
	p {
		transform: translate3d(0, -60%, 0);
		color: $white;
	}

	h2 {
		transition: transform 0.35s;
	}

	p {
		color: rgba(255, 255, 255, 0.8);
		opacity: 0;
		transition: opacity 0.2s, transform 0.35s;
	}

	&:hover {
		img,
		p {
			opacity: 1;
		}

		img {
			transform: scale(1);
		}

		h2 {
			transform: translate3d(0, -80%, 0);
		}

		p {
			transform: translate3d(0, -80%, 0);
			transition-delay: 0.05s;
			transition-duration: 0.35s;
		}
	}
}

//

.card-calendar {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.5rem;

	&__logo {
		display: flex;
		align-items: center;
		justify-items: center;
		max-width: 250px;
		height: 180px;
	}

	&__title {
	}

	&__data {
	}

	&__subtitle {
		font-family: $font-family-headings;
		font-weight: bold;
		text-transform: uppercase;
		color: $primary;
	}
}

// Galery-regalos

.gallery-regalos {
	img {
		width: 100%;
		height: 150px;
		object-fit: contain;
		border: 1px solid $gray-400;
		padding: 0.5rem;
	}
}

/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */

input {
	&[type="email"],
	&[type="number"],
	&[type="search"],
	&[type="text"],
	&[type="tel"],
	&[type="url"],
	&[type="password"] {
		appearance: none;
		height: 38px;
		padding: 0.7rem 1rem;
		background-color: $white;
		border: 1px solid $gray-500;
		border-radius: 0;
		box-shadow: none;
		box-sizing: border-box;
	}
}

input {
	&[type="email"]:focus,
	&[type="number"]:focus,
	&[type="search"]:focus,
	&[type="text"]:focus,
	&[type="tel"]:focus,
	&[type="url"]:focus,
	&[type="password"]:focus {
		border: 1px solid $primary;
		outline: 0;
	}
}

input {
	&[type="checkbox"],
	&[type="radio"] {
		display: inline;
	}
}

textarea,
select {
	height: 3rem;
	padding: 0.7rem 1rem;
	background-color: $white;
	border: 1px solid $gray-500;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
}

textarea:focus,
select:focus {
	border: 1px solid $primary;
	outline: 0;
}

textarea {
	appearance: none;
	min-height: 100px;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
}

label,
legend {
	display: block;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}

fieldset {
	padding: 0;
	border-width: 0;
}

label > .label-body {
	display: inline-block;
	margin-left: 0.5rem;
	font-weight: normal;
}

.form-group,
.form-horizontal {
	margin: 1rem 0;

	label,
	select,
	textarea {
		display: block;
		width: 100%;
		margin-top: 0.8rem;
	}

	input {
		&[type="email"],
		&[type="number"],
		&[type="search"],
		&[type="text"],
		&[type="tel"],
		&[type="url"],
		&[type="password"] {
			display: block;
			width: 100%;
		}
	}
}

//
.logout {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1.1rem;
}

.table-app > :not(:first-child) {
	border-top: none;
}

.table-app {
	th,
	td {
		vertical-align: middle;
	}

	th {
		border-bottom: 2px solid black;

		h4,
		h5 {
			margin-top: 2rem;
			margin-bottom: 0;
			color: $body-color;
		}
	}

	.btn {
		min-width: 150px;
	}
}

.table-app > :not(caption) > * > * {
	padding: 1rem 0.5rem;
}

.item-participante1,
.item-participante2,
.item-participante3 {
	padding: 0.6rem;
	border: 1px solid rgba($dark, 0.2);

	select {
		font-size: 0.8rem;
	}
}

label.participante-1,
label.participante-2,
label.participante-3 {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.1rem;
}

.card-pricing {
	.card-pricing-title {
		transform: skewX(-10deg);
	}
}

.img-effect {
	-webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
	clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
	border-radius: 8px;
}

.item-aceptacion {
	display: flex;
	align-items: baseline;

	:nth-child(1) {
		order: 2;
		margin-left: 0.3rem;
	}
}

.num-equipo {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	background-color: $gray-300;
	color: $body-color;
	font-size: 1rem;
	font-weight: bold;
	width: 30px;
	height: 30px;
}

.form-prepare {
	label {
		display: inline-block;
		font-weight: bold;
		width: auto;
	}

	.form-group {
		margin: 0;
	}
}

.btn-logout {
	white-space: nowrap;
}

.date,
.fecha {
	letter-spacing: 2px;
}

/* Cookies  */

#cookies {
	.bg-cookies {
		background-color: rgba($dark, 0.9);
		color: $white;

		h2,
		p {
			color: $white;
		}

		h2 {
			font-size: 1rem;
		}

		p {
			font-size: 0.8rem;
		}
	}

	.button-fixed {
		bottom: 0;
		position: fixed;
		right: 0;
	}
}

.termsfeed-com---palette-dark .cc-nb-okagree,
.termsfeed-com---palette-dark .cc-nb-reject,
.termsfeed-com---palette-dark .cc-cp-foot-save {
	background-color: $primary !important;
	color: $white !important;
}
.termsfeed-com---palette-dark .cc-pc-head-lang select:focus {
	box-shadow: 0 0 0 2px $primary !important;
}

.termsfeed-com---reset button {
	border-radius: 0 !important;
}

/* timeline */

.timeline {
	border-left: 3px solid $primary;
	background: transparent;
	margin: 0 auto 0 200px;
	position: relative;
	font-size: 1rem;
	padding: 1.5rem;
	list-style: none;
	text-align: left;
	width: auto;

	h1 {
		font-weight: 300;
		font-size: 1.4em;
	}

	p {
		margin-bottom: 0;
	}

	.event {
		border-bottom: 1px dashed $gray-600;
		padding-bottom: 25px;
		margin-bottom: 25px;
		position: relative;

		&:last-of-type {
			padding-bottom: 0;
			margin-bottom: 0;
			border: none;
		}

		&:before,
		&:after {
			position: absolute;
			display: block;
			top: 0;
		}

		&:before {
			left: -170px;
			content: attr(data-date);
			text-align: right;
			font-weight: bold;
			font-size: 1rem;
			min-width: 120px;
		}

		&:first-child {
			color: $primary;
			text-transform: uppercase;
		}

		&:after {
			box-shadow: 0 0 0 3px $primary;
			left: -30px;
			background: $white;
			border-radius: 50%;
			height: 9px;
			width: 9px;
			content: "";
			top: 5px;
		}
	}
}

@media (max-width: 767px) {
	.timeline {
		margin-left: 0;
		width: 100%;
	}

	.timeline .event {
		padding-top: 30px;
	}

	.timeline .event:before {
		left: 0px;
		text-align: left;
	}
}

/* Google map */

.map-container {
	width: 100%;
	max-width: 800px;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
	border: 3px solid $primary;

	iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	}
}

/* form */

.form-max-width {
	max-width: 400px;
	margin: auto;
}

.logo-sponsor {
	width: auto;
	height: 30px;
	margin: 1rem;

	@include media-breakpoint-up(md) {
		height: 40px;
	}
}

.logo-michelin {
	@include media-breakpoint-up(md) {
	}
}

.logo-tuc-blanc {
	@include media-breakpoint-up(md) {
	}
}

.section-info {
}

.content-slogan {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2rem;
	margin-bottom: 4rem;

	&-title {
		margin-bottom: 2rem;
	}

	@include media-breakpoint-up(md) {
		padding: 5rem;
		margin-bottom: 0;
	}
}

.section-cta {
	background: url("../../img/front/bg-snow.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-size: cover;
	padding: 6rem 0;
}

.img-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* Alerta */

#mainNav {
	flex-direction: column;
	padding-top: 0;
}

.alert-box {
	text-align: center;
	background-color: #fff12e;
	color: $dark;
	padding: 0.5rem;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 700;
	width: 100%;
	background-image: linear-gradient(135deg, #fff12e 25%, #000000 25%, #000000 50%, #fff12e 50%, #fff12e 75%, #000000 75%, #000000 100%);
	background-size: 16.97px 16.97px;
	padding: 5px 0;

	@include media-breakpoint-down(lg) {
		margin-bottom: 10px;
	}

	&-content {
		display: flex;
		background-color: #fff12e;

		.col {
			padding: 5px 0;
		}

		.col:nth-child(odd) {
			background-color: $dark;
			color: #fff12e;

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
	}

	.fa,
	.far,
	.fas {
		margin: 0 3px;
	}

	.btn-close {
		color: #000;
		padding: 8px;
		background: none;
		font-size: 16px;
		@include media-breakpoint-up(lg) {
			color: $white;
		}
	}
}

.flash-error {
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1;
}

/* Fin Alerta */
.container-sm {
	max-width: 960px;
}

.container-xs {
	max-width: 760px;
}

.text-heading {
	font-family: $font-family-headings;
}

.img-bg-ar {
	height: 40vh;
	object-fit: cover;
}

h1.page-introduce-title,
h2.page-introduce-subtitle,
.ff-title {
	font-family: $font-family-tipo;
}

.gallery-sorteo {
	@include media-breakpoint-up(lg) {
		.col-lg-3 {
			width: 33.33333333% !important;
		}
	}
}

.links-policy {
	a {
		color: $white;
	}
}
